@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

.verfiyMain {
	width: 100%;
	max-width: 450px;
	height: 100%;
	background-color: black;
	/* min-height: 100vh; */
	position: relative;
	box-sizing: border-box;
}
.App {
	position: relative;
}
.verfiyTop {
	padding: 14px 10px;
	background-color: rgba(255, 23, 23, 1);
	box-sizing: border-box;
	width: 100%;
}

.verfiyTop h1 {
	font: 600 15px/20px "Montserrat", sans-serif;
	color: white;
	text-align: center;
}

.verifyBody {
	width: 100%;
	padding: 14px 10px;
	box-sizing: border-box;
	margin-bottom: 120px;
}

.homeUImage {
	width: 35%;
	margin: 0 auto 17px auto;
}

.homeUImage img {
	width: 100%;
}

.inputDetails {
	width: 100%;
	margin-top: 10px;
	padding: 0 10px 10px;
	box-sizing: border-box;
}

.inputDetails p.para {
	font: 600 15px/20px "Montserrat", sans-serif;
	color: white;
	margin: 4px 0;
}

.inputCover {
	position: relative;
}
.margin10 {
	margin-top: 15px;
}
.profileInput {
	width: 100%;
	box-sizing: border-box;
	background: rgba(44, 41, 41, 1);
	outline: 0;
	border: 0;
	color: white;
	border-radius: 4px;
	padding: 10px;
}
.otpInput {
	background: rgba(44, 41, 41, 1);
	border-radius: 4px;
	padding: 10px;
	outline: 0;
	border: 0;
	width: 51px !important;
	color: white;
	box-sizing: border-box;
	margin: 0 auto 0 0;
}
.otpInput.aadharInput {
	width: 8% !important;
	padding: 10px 4px;
	margin-right: 2px;
}
.inputDetails p.otp {
	position: absolute;
	top: 26%;
	font: 500 14px/17px "Montserrat", sans-serif;
	color: rgba(255, 23, 23, 1);
	right: 10px;
	text-align: left;
	cursor: pointer;
	margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
.verfiysubmitCover {
	width: 100%;
	margin: 10px 0;
	text-align: center;
}
.verifysubmit {
	padding: 10px 44px;
	width: fit-content;
	cursor: pointer;
	border-radius: 4px;
	color: white;
	margin: 0 auto;
	background-color: rgba(255, 23, 23, 1);
	border: 0;
	font: 500 14px/17px "Montserrat", sans-serif;
}
.verfiysubmitCover.flexWrap .verifysubmit {
	border-radius: 20px;
}

.dialogs {
	width: 100%;
	max-width: 450px;
}
.resendButton {
	-webkit-tap-highlight-color: transparent;
}
div:has(> .dialogBox),
.MuiPaper-root {
	background-color: transparent !important;
	border-radius: 20px !important;
	width: 100%;
	margin: 10px 0 !important;
	-webkit-background-color: transparent !important;
	-webkit-border-radius: 20px !important;
	-webkit-width: 100%;
	-webkit-margin: 10px 0 !important;
}
.dialogBox {
	width: 100%;
	background: transparent;
}
.dialogCover {
	text-align: center;
	color: white;
	background: rgba(44, 41, 41, 1);
	margin: 0 auto;
	padding: 35px 15px;
	text-align: center;
	box-sizing: border-box;
	width: 90%;
	border-radius: 10px;
}

.iconsImage {
	width: 54px;
	margin: 0 auto;
}
.iconsImage svg {
	width: 100%;
}

.messageBox p {
	color: white;
	font: 500 14px/20px "Montserrat", sans-serif;
}

.aadhaarDetails {
	width: 100%;
	margin-top: 10px;
	padding: 0 10px 10px;
	box-sizing: border-box;
}
.aadhaarDetails h3 {
	color: white;
	margin: 10px 0;
	font: 500 20px/25px "Montserrat", sans-serif;
}

.basicDetail {
	width: 100%;
	margin-bottom: 10px;
}
.flexWrap {
	display: flex;
	flex-wrap: wrap;
}

.basicDetail p {
	color: white;
	margin: 6px 0;
	font: 500 14px/20px "Montserrat", sans-serif;
}
.basicDetail p.detailBox {
	display: flex;
	align-items: center;
	margin: 16px 0;
	justify-content: space-between;
	padding: 8px;
	border-radius: 10px;
	border: 1px solid white;
}
.eyebox{
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	border: 1px solid white;
	display: inline-flex;
	padding: 4px 8px;
	border-radius: 100%;
	font: 500 14px/20px "Montserrat", sans-serif;
}
.basicDetail p span {
	color: rgba(171, 171, 171, 1);
}

.okay {
	width: 200px;
	text-align: center;
	background: rgba(255, 23, 23, 1);
	padding: 12px;
	border-radius: 10px;
	cursor: pointer;
	margin: 0 auto;
	box-shadow: 0px 9px 26px 0px rgba(255, 23, 23, 0.2);
}

.inputCover.mb20 {
	margin-bottom: 20px;
}
.countdown-text {
	display: flex;
	flex-wrap: wrap;
	padding: 0 10px 10px;

	justify-content: space-between;
}
.countdown-text p {
	color: white;
	margin: 6px 0;
	font: 500 14px/20px "Montserrat", sans-serif;
}
.countdown-text button {
	color: white;
	background-color: transparent;
	outline: none;
	border: 0;
	opacity: 0.4;
	text-decoration: underline;
	cursor: pointer;
}
.loaderKaBox {
	width: 100%;
	height: 100vh;
}
.loaderKA {
	width: 250px;
	margin: 0 auto;
	display: block;
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.resendButton p {
	color: rgba(255, 255, 255, 0.477);
}
.resendButton.active p {
	color: white;
}
.loaderKaBox p {
	width: 100%;
	margin: 0 auto;
	display: block;
	position: absolute;
	top: 62%;
	color: white;
	text-align: center;
	left: 50%;
	transform: translate(-50%, -50%);
}

.detailFlexW {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	box-sizing: border-box;
	justify-content: space-between;
}

.borderWhite {
	border-radius: 10px;
	position: relative;
	width: 30%;
	text-align: center;
	padding: 2px 10px;
	margin-bottom: 15px;
	border: 1px solid white;
	box-sizing: border-box;
}
.borderWhite.fourth{
	width:   23%;
}

.borderWhite:last-child{
	width: 100%;
	border: 2px solid white;
}
.borderWhite.half{
	border: 2px solid white;
	width: 48% !important;
	display: flex;
	align-items: center;
	justify-content:  center;
}
.radioButton{
	width: 20px;
	height: 20px;
	border-radius: 100%;
	border: 2px solid white;
	display: flex;
	align-items: center;
	justify-content:  center;
}
 .radioFill{
	width: 12px;
	height: 12px;
	border-radius: 100%;
	background:  transparent;
}
.borderFlex.w25{
	width: 20%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.borderFlex.w70{
	width: 80%;
	/* display: flex;
	align-items: center;
	justify-content: center; */
}
.radioFill.fill{
	background-color: white;
}
.borderWhite p {
	color: white;
	margin: 6px 0;
	font: 500 12px/18px "Montserrat", sans-serif;
}
.borderWhite.fourth p{
	height: 36px;
}
.borderWhite:nth-child(4) p {
	font: 600 14px/18px "Montserrat", sans-serif;
}
.inputDetails.p0 {
	padding: 0;
}
.eyeMsg {
	position: absolute;
	background-color: white;
	color: black;
	border-radius: 4px;
	bottom: 100%;
	right: 0;
	width: 255px;
}
.eyeMsg p {
	color: black;
	font: 500 11px/18px "Montserrat", sans-serif;
}
.eyeBox {
	position: absolute;
	top: 3px;
	right: 3px;
	width: 15px;
}
.winnPara.bold {
	font: 600 13px/20px "Montserrat", sans-serif;
}

.winnPara.bold.bottom {
	position: fixed;
	bottom: 3%;
	left: 2%;
	max-width: 440px;
	width: 90%;
}

.newNote {
	width: 100%;
	margin-top: 10px;
	padding: 0 10px 10px;
	box-sizing: border-box;
}

.newNote h3 {
	font: 600 17px/20px "Montserrat", sans-serif;
	color: white;
	text-align: center;
}

.newNote p {
	font: 600 13px/20px "Montserrat", sans-serif;
	color: white;
}

.newNote ol {
	font: 600 11px/23px "Montserrat", sans-serif;
	color: white;
	padding-inline-start: 20px;
}
.newNote ol li {
	margin-bottom: 8px;
}
.queryBox{
	width: 100%;
	padding: 10px 10px 10% 20px;
    color: white;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: center;
	justify-content: center;
	position: fixed;
    bottom: 0%;
    left: 0;
max-width: 450px;
	background-color: black;
}

.queryBox h3{
	margin: 0;
	font: 600 15px / 20px "Montserrat", sans-serif;
	width: fit-content;
}
.whatsappBox{
	background: rgba(76 175 80);
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-left: 10px;
    width: -moz-fit-content;
    width: fit-content;
}
.whatsappBox span{
	width: 20px;
	height: 20px;
	margin-right: 8px;
}
.whatsappBox span img{
	width: 100%;
}
.whatsappBox p{
	margin: 0;
	font: 500 12px / 17px "Montserrat", sans-serif;

}
.suBtext{
	width: 100%;
	color: #fff;
	margin-top: 16px;
	font: 500 12px / 17px "Montserrat", sans-serif;
	text-align: center;

}
.yesnobox{
	display: flex;
	align-items: center;
	justify-content: space-around;

}
.yesnobox .okay{
	width: 45%;
	box-sizing: border-box;
	padding: 8px 8px;
}
.errorMsgRummy{
	width: 100%;
	color: red;
	margin-top: 16px;
	font: 500 12px / 17px "Montserrat", sans-serif;
	text-align: left;margin: 0;
}