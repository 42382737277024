
body{
    height: 100vh;
}
/* .App{
    height: 100vh;
    overflow: hidden;
} */

.notice{
    height: 70vh;
    overflow: scroll;
    color: white;
    width: 100%;
    padding: 20px;
    /* border: 1px solid red; */
}

.notice p:first-child{
    font: 600 20px/20px "Open Sans", sans-serif;
}


/* .notice p{
    font: 400 15px/20px "Open Sans", sans-serif;
} */
.notice li{
    margin-bottom: 1rem;
    font: 400 15px/20px "Open Sans", sans-serif;
}

.no_tickets{
    display: flex;
    align-items: center;
    font: 400 25px / 20px "Hahmlet", sans-serif;
    color: white;
    justify-content: center;
}

.no_tickets.zero{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}






.contestpic{
    display: flex;
    align-items: center;
    justify-content: center;
}

.contestpic svg{
  width: 100%;
  height: 100%;
}
.swiper_layout{
    height: 130px;
    margin-bottom: 3rem;
}

.leaderBoard_slider_swiper {
	width: 90%;
	height: 115%;
}

.leader_image,
.leader_image img {
	width: 100%;
	height: 120px;
	border-radius: 7px;
	/* object-fit: cover; */
}

.ticket_heading{
    color: #e6c983;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font: 600 23px/20px "Hahmlet", sans-serif;
}

.ticket_heading :nth-child(2){
    font: 600 20px/18px "Hahmlet", sans-serif;
}
.ticketLine{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ticketLine svg{
    width: 100%;
    height: 40px;
}

.ticket_div{
    /* border: 1px solid red; */
    height: 34vh;
    overflow-y: scroll;
}

.ticket_div.zeroTicket{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
}

.ticket_layout{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-bottom: 2rem;
}

.ticket{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    /* height: 140px; */
    box-shadow: 1px 0px 189.3px 0px #91919140;
    border-radius: 8px;
    min-height: 140px;
}

.left_ticket{
    /* background: red; */
    background: linear-gradient(175.31deg, #EF2D2D -5.75%, #950707 80.33%);
    width: 20%;
    height: 100%;
    border-radius: 8px 0 0 8px;

    display: flex;
    align-items: center;
    position: relative;
}

.left_ticket span{
   position: absolute;
   display: block;
   width: 40px;
   height: 40px;
   background-color: black;
   border-radius: 50%;
   left: -18px;
} 

.dotted_border{
    border: 2px solid #463D2E;
    border-radius: 0 8px 8px 0;
    border-style: dashed;
    position: relative;
    height: 100%;
    padding: 8px;
    color: #463D2E;
    font: 500 12px/25px "Hahmlet", sans-serif;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: none;
}

.dotted_border p {
    font-weight : lighter;
}


.right_ticket{
    background: #E3C4A1;
    width: 80%;
    height: 100%;
    border-radius: 0 8px 8px 0;
    padding: 5px 5px 5px 0;
}

.tick_number{
    width: fit-content;
    margin-bottom: -8px;
    font: 500 18px/25px "Hahmlet", sans-serif;
}

.tick_number::after{
  display: block;
  content: "";
  background: red;
  width: 100%;
  height: 4px;
}

.valid_text{
    width: fit-content;
}

.valid_text::before{
    display: block;
    content: "";
    /* background: red; */
    /* height: 4px; */
    border: 1px dashed #463D2E4D;
    margin-bottom: 1em;
}

.ticket_logo{
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
  transform: translate(-10px, 5px);
}

.ticket_logo img{
  width: 100%;
  height: 100%;
}

.bottom_buttons{
    display: flex;
    align-items: center;
    justify-content : flex-end;
    padding-right: 1.6rem;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    gap: 10px;
    text-align: left;
}


.seemore_button{
    background: linear-gradient(175.31deg, #EF2D2D -5.75%, #950707 80.33%);
    color: white;
    padding: 10px 20px;
    border-radius: 7px;
    font: 500 14px / 15px "openSans", sans-serif;
    width: fit-content;
    /* margin-top: 10px; */
}
.alltickets{
    font: 500 20px / 22px "Hahmlet", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 30px 0;
}

.Bigcheck_winner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    padding-bottom: 15px;
}

.check_winner{
    /* display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 12px 16px;
    border: 1px solid #033004;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 0 0 0px auto;
    background-color: #279527; */

    display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 8px;
    border: 1px solid white;
    border-radius: 10px;
    box-sizing: border-box;
    /* margin: 0 20px 10px auto; */
    /* background-color: #279527; */
    background: linear-gradient(175.31deg, #66e75e -5.75%, #146814 80.33%);
    border: 1px solid #033004;
    /* margin-right: 20px; */
}
.check_winner p{
    margin: 0 5px 0 0;
    color: white;
    font: 400 14px / 15px "openSans";
}

.you_won{
    color: #279527;
    font: 800 18px/25px "Hahmlet";
}
.you_lost{
    color: red;
    font: 800 18px/25px "Hahmlet";
}


.logoutButton svg{
    height: 18px;
    width: 20px;
}


/*View in dark mode for good effects*/
.loaderes {
    border: 4px solid red;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-right-color: transparent;
    animation: rot 1s linear infinite;
    box-shadow: 0px 0px 20px red inset;
    margin: auto;
  }
  
  @keyframes rot {
    100% {
      transform: rotate(360deg);
    }
  }

  .spinner {
    --gap: 5px;
    --clr: #d62d20;
    --height: 23px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gap);
    margin: auto;
   }
   
   .spinner span {
    background: var(--clr);
    width: 6px;
    height: var(--height);
    animation: grow .6s ease-in-out infinite;
   }
   
   .spinner span:nth-child(2) {
    animation: grow .6s ease-in-out 0.15s infinite;
   }
   
   .spinner span:nth-child(3) {
    animation: grow .6s ease-in-out 0.3s infinite;
   }
   
   .spinner span:nth-child(4) {
    animation: grow .6s ease-in-out 0.475s infinite;
   }
   
   @keyframes grow {
    0%,100% {
     transform: scaleY(1);
    }
   
    50% {
     transform: scaleY(1.8);
    }
   }


   .list-style{
       list-style-type :square;
   }

 

 .youtubeVideo {
	/* border: 1px solid white; */
	border-radius: 10px;
    padding: 15px 10px 10px 10px;
}
.youtubeVideo iframe {
	border-radius: 10px;
}
.profilePlay {
	/* width: 300px !important;
	height: 200px !important; */
	width: 100% !important;
	height: 200px !important;
}