@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@font-face {
	font-family: PoppinsBold;
	src: url(./font/Poppins/Poppins-Bold.ttf);
}

@font-face {
	font-family: PoppinsSemiBold;
	src: url(./font/Poppins/Poppins-SemiBold.ttf);
}
@font-face {
	font-family: OpenSansSemiBold;
	src: url(./font/Open_Sans/static/OpenSans-SemiBold.ttf);
}
@font-face {
	font-family: openSans;
	src: url(./font/Open_Sans/static/OpenSans-Regular.ttf);
}
@font-face {
	font-family: PassionOne;
	src: url(./font/Passion_One/PassionOne-Regular.ttf);
}
@font-face {
	font-family: Hahmlet;
	src: url(./font/Hahmlet/static/Hahmlet-Regular.ttf);
}
html {
	height: 100%;
	font-family: "Montserrat", sans-serif;
	scroll-behavior: smooth;
	user-select: none;
}
body {
	margin: 0;
	height: 100% !important;
	/* height: auto !important; */
	width: 100%;
}
.App {
	/* position: fixed;
	top: 0;*/
	overflow: scroll;
	width: 100%;
	height: 100% !important;
	max-width: 450px;
	min-height: 100vh;
	background: black;
}
.container {
	max-width: 1100px;
	margin: 0 auto;
	overflow: hidden;
	width: 100%;
}
.ludoBox {
	padding: 30px;
}
.container .leaderHeading {
	width: 100%;
	text-align: center;
	margin: 0 0 10px;
}
.topperBox {
	width: 100%;
	display: flex;
	box-sizing: border-box;
	padding: 30px;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}
.topperBox div.ranksBox:nth-child(2) {
	margin-top: -5%;
}
.topperBox .ranksBox {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 33%;
}
.topperBox .ranksBox h2 {
	font-size: 14px;
	margin: 0;
	word-wrap: break-word;
}
.topperBox .ranksBox p {
	font-size: 14px;
	margin: 0;
	word-wrap: break-word;
}
.topperBox .ranksBox .rankDetail {
	max-width: 70px;
	max-height: 70px;
	overflow: auto;
	border-radius: 100%;
	border: 1px solid black;
}
.topperBox .ranksBox .rankDetail img {
	width: 100%;
}
.leaderBoardTable {
	width: 100%;
	text-align: left;
	margin-bottom: 30px;
}
.leaderBoardTable tr {
	display: flex;
	flex-wrap: wrap;
	padding: 5px;
	text-align: center;
	border-radius: 5px;
	margin: 5px 0;
}
.leaderBoardTable tr:nth-child(even) {
	background-color: #e81c23;
	color: white;
}
.leaderBoardTable tr th {
	width: 33%;
	border-collapse: collapse;
}
.leaderBoardTable tr td {
	width: 33%;
	border-collapse: collapse;
}
.khiladiLogo {
	display: none;
}
.note {
	width: 100%;
	color: black;
	font-size: 13px;
	padding: 10px;
	position: fixed;
	bottom: 0;
	background-color: white;
	margin: 0;
}
.containerForm {
	padding: 10px;
}
.containerForm h1 {
	font-size: 20px;
	width: 100%;
	text-align: center;
}
#myForm {
	width: 100%;
	box-sizing: border-box;
	background-color: #fff;
	max-width: 300px;
	margin: 0 auto;
	border-radius: 24px;
	padding: 20px;
	box-shadow: -2px 2px 8px -5px rgb(0 0 0 / 75%);
}
.inputCover {
	display: block;
	width: 100%;
}
.inputCover label {
	margin: 5px 0;
	display: block;
	width: 100%;
	font-size: 15px;
	text-transform: uppercase;
}
.inputCover #games {
	width: 100%;
	background-color: #f4f8fa;
	padding: 10px 5px;
	font-family: "Montserrat", sans-serif;
	border: 1px solid #a2b0bb;
}
.buttonGroup {
	display: flex;
	flex-wrap: wrap;
}
.submit {
	background: #475b69;

	border: 1px solid transparent;
	border-radius: 8px;
	box-shadow: 0 0 3px 1px #fff;
	color: #fff;
	cursor: pointer;
	display: block;
	font-family: Montserrat, sans-serif;
	font-size: 20px;
	margin: 10px auto;
	padding: 7px;
	width: 45%;
}
.bannerBox {
	width: 100%;
	display: flex;
	justify-content: center;
}
.bannerBox a {
	width: 100%;
	height: 100vh;
}
.bannerBox a img {
	display: block;
	margin: auto;
	width: auto;
	height: 100%;
}

.winnerHeadBox {
	display: block;
	position: relative;
}
.filterBox {
	position: absolute;
	top: -10%;
	right: 3%;
}
.filterBox img {
	width: 30px;
	height: auto;
}
.dropDownBox {
	display: block;
	width: 120px;
	padding: 5px 0 0;
	position: absolute;
	top: 100%;
	right: 0%;
	box-shadow: 0px 1px 2px 0px #998a8a;
	border-radius: 10px;
	background-color: white;
}
.boxes {
	width: 100%;
	overflow: hidden;
}
.boxes h4 {
	width: 100%;
	padding: 5px 0px 5px 9px;
	margin: 0%;
	box-sizing: border-box;
	font-size: 14px;
}
.boxes h5,
.boxes h6 {
	width: 100%;
	margin: 0;
	padding: 5px 9px;
	font-weight: 400;
	font-size: 0.83rem;
	overflow: hidden;
	border-radius: 0 0 10px 10px;
	cursor: pointer;
	box-sizing: border-box;
}
.boxes h5:hover,
.boxes h6:hover {
	background-color: #b6acac;
}
.center {
	width: 100%;
	text-align: center;
}
.basicDetail p.changes {
	text-align: center;
	text-transform: uppercase;
	font: 700 13px/20px "Montserrat", sans-serif;
}
.winnPara {
	margin: 3px 0;
	font: 400 13px/20px "Montserrat", sans-serif;
	color: white;
}
.maintenacneMode {
	width: 100%;
	height: 100%;
	text-align: center;
	color: white;
	padding: 0 10px;
	box-sizing: border-box;
}

.maintenacneMode .homeUImage {
	width: 100%;
	margin: 20px 0;
}
.maintenacneMode h4 {
	font: 900 19px/20px "Montserrat", sans-serif;
}
.maintenacneMode p {
	margin-bottom: 36px;
}
.verfiyTop.main h1 {
	font: 900 19px/20px "Montserrat", sans-serif;
}
@media screen and (max-width: 700px) {
	.bannerBox a img {
		width: 100%;
		height: auto;
	}
	.khiladiLogo {
		display: block;
		position: absolute;
		top: 30px;
		width: 50px;
	}

	.ludoBox {
		background: rgb(28 28 28 / 90%);
		background: linear-gradient(110deg, #de3717ed 0%, #28282af5 100%);
		color: white;
		padding: 30px 5px;
		border-radius: 0 0 40% 40%;
		box-sizing: border-box;
		width: 100%;
	}
	.container .leaderHeading {
		font-size: 22px;
	}
	.topperBox {
		padding: 20px 10px;
	}
	.topperBox .ranksBox .rankDetail {
		max-width: 50px;
		max-height: 50px;
		overflow: hidden;
	}
	.topperBox div.ranksBox:nth-child(2) {
		margin-top: -15%;
	}
	.topperBox div.ranksBox:nth-child(odd) {
		margin-top: 15%;
	}
	.topperBox .ranksBox h2 {
		font-size: 14px;
		text-align: center;
		font-weight: 500;
	}
	.winnerHeading {
		width: 100%;
		text-align: center;
	}
	.leaderBoardTable {
		padding: 0 5px;
	}
	.leaderBoardTable tr:first-child {
		flex-wrap: nowrap;
		box-shadow: none;
		padding: 10px 5px;
	}
	.leaderBoardTable tr {
		flex-wrap: nowrap;
		box-shadow: 0px 5px 8px -5px rgb(0 0 0 / 75%);
		padding: 20px 5px;
	}
	.leaderBoardTable tr td:nth-child(1),
	.leaderBoardTable tr td:nth-child(3),
	.leaderBoardTable tr th:nth-child(1),
	.leaderBoardTable tr th:nth-child(3) {
		width: 20%;
	}
	.leaderBoardTable tr td:nth-child(2),
	.leaderBoardTable tr th:nth-child(2) {
		width: 60%;
	}
}
