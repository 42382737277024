*{
    box-sizing: border-box;
}
.r1_login{
    width: 100%;
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.r1_login_logo{
    margin: 10px auto;
    display: flex;
    justify-content: center;
    transition: all 0.9s ease-in-out;
}
.smallLogo{
    margin: 10px auto 10px 15px;
}
.r1_khiladiLogo{
    margin: 0 auto;
    width: 100%;
    max-width: 150px;
    transition: all 0.9s ease-in-out;
    object-fit: contain;
}
.smallLogo .r1_khiladiLogo{
    max-width: 100px;
}
.r1_login_head{
    margin: 20px 0 0;
    width: 100%;
    position: relative;
}
.r1_login_head h3{
	font: 600 26px/28px "PoppinsSemiBold";
    text-transform: uppercase;
    color: white;
	text-align: center;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
}
.r1_login_head p{
    margin: 0;
    padding: 0;
	font: 600 18px/18px "PoppinsSemiBold";
    text-transform: uppercase;
    color: white;
	text-align: center;
}
.r1_number_box{
    width: 100%;
    padding: 10px 15px;
    margin-top: 60px;
}
.r1_login_head2{
    font: 600 21px/22px "OpenSansSemiBold";
    color: white;
    margin:  0 0 20px 0;
}
.r1_login_para2{
    
    font: 600 13px/19px "openSans";
    color: white;
}
.r1_login_input {
	display: block;
	width: 100%;
}
.r1_login_input label {
	margin: 5px 0;
	display: block;
	width: 100%;
	font-size: 15px;
}
.r1_login_input_box{
    -webkit-backdrop-filter: blur(25.76px);
    backdrop-filter: blur(25.76px);
    background: linear-gradient(86deg, hsla(0, 0%, 100%, .2) 11.14%, hsla(0, 0%, 100%, .04) 113.29%);
    border: 2px solid #797979;
    border-radius: 8px;
    box-shadow: none;
    box-sizing: border-box;
    color: #fff;
    font: 500 16px / 20px OpenSans;
    height: 50px;
    margin-bottom: 15px;
    outline: none;
    padding: 8px 17px;
    width: 100%;
}
.r1_get_otp{
    background: linear-gradient(161.85deg, #EF2D2D 20.44%, #B8071C 85.85%);
	font: 500 14px/17px "OpenSans", sans-serif;
    text-align: center;
    border-radius: 8px;
    width: 100%;
    padding: 12px 10px;
	color: white;
	cursor: pointer;
	margin:10px 0  0;
}

.r1_login_star1{
   position: absolute;
   top: -38%;
   left: 9%;
}

.r1_login_star2{
    position: absolute;
    top: -37%;
    right: 9%;
    transform: rotate(130deg);
}
.r1_login_star3{
    position: absolute;
    bottom: 0;
    left: 16%;
    transform: rotate(30deg);
}

.r1_login_star4 {
    position: absolute;
    bottom: 0;
    right: 17%;
    transform: rotate(70deg);
}
.r1_login_otp_input{
    width: 100%;
    margin-top: 60px;

    padding: 10px 15px;
}
.r1_login_para2.para3{
    font: 500 14px/17px "OpenSans", sans-serif;
margin: 0;
}
.r1_login_para4{
    cursor: pointer;
    font: 500 15px/17px "OpenSans", sans-serif;
margin: 0;
color: #8AC9FE;
}
.r1_login_para4.light{
    color: #8acafe9a;

}
.r1_login_para4.light.active{
    color: #8AC9FE;

}
.spaceBetween{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.otpInput{
    backdrop-filter: blur(25.731300354003906px);
    box-shadow: 0px 0.57px 14.29px 0px #452A7C1A;
    background: linear-gradient(86deg, hsla(0, 0%, 100%, .2) 11.14%, hsla(0, 0%, 100%, .04) 113.29%);
    border: none;
    border-radius: 9px;
    box-shadow: none;
    height: 50px;
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    margin: 19px 7px 0 0;
    border: 1.4px solid #797979;
    width: 44px !important;
}
.spaceBetween.mt10{
    margin-top: 12px;
    margin-bottom: 16px;
}
.r1_daily_cover{
    width: 100%;
    display: block;
    margin: 30px auto 0;
    
}
.r1_outerCircle{
    width: 212px;
    height: 212px;
    border-radius: 50%;
background: linear-gradient(183.76deg, #EF2D2D -9.28%, #BA1540 107.29%);
    box-sizing: border-box; 
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
}
.r1_black{
    width: 200px;
    height: 200px;
    background-color:  black;
    border-radius: 100%;
}

.dayName{
    font: 800 27px/22px "openSans";
    text-align: center;
    color: white;
    margin: 20px 0 0;
}
.three_tier{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 18px 10px 10px;
    box-sizing: border-box;
    justify-content: space-between
}
.tier_box{
    width: 32%;
    padding: 6px 6px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 4px 30px 0px #FFFFFF40;
}
.flex_Area{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}
.flex_Area h6{
    font: 800 18px/22px "openSans";
    color:  #313131;
    margin: 0;
}
.tier_box p{
    font: 400 13px/14px "openSans";
    color:  #212121;
    margin: 5px 0;
    text-align: center;
}

.info_text{
    font: 800 15px/15px "openSans";
    text-align: center;
    color: white;
    margin: 10px 0 0;
    font-family: Inter;
    font-weight: 500;
}
.wallet_button{
    font: 800 15px/15px "openSans";
    background: linear-gradient(161.85deg, #EF2D2D 20.44%, #B8071C 85.85%);
    color: #ffffff;
    border-radius: 11px;
    margin: 25px 0 0;
    padding: 15px 35px;
}
.remaining_days{
    font: 800 27px/22px "openSans";
    color: white;
    margin: 30px 0 6px 0;
    width: 85%;
}
.congo_text{
    font: 900 12px / 15px "openSans";
    color: white;
    margin: 10px 0 0;
    font-weight: 500;
    width: 85%;
}

.cicles_layout{
    display: flex;
    gap: 25px;
    width: 85%;
    overflow-x: auto;
}


.days_circle_outside{
    position: relative;
    margin: 20px 0 10px 0;
    width: 85%;
    height: 100px;
}


.big_days_circle{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    /* background: linear-gradient(183.76deg, #EF2D2D -9.28%, #BA1540 107.29%); */
    border: 8px solid #EF2D2D  ;
    /* box-sizing: border-box;  */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.big_daycircle_inside{
    width: 160px;
    height: 160px;
    background-color:  black;
    border-radius: 100%;
    /* border: 8px solid #EF2D2D  ; */
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font: 600 35px/20px "openSans";
}
.percentage_text{
    font: 600 15px/20px "openSans";
    color: #7B7B7B;
}

.limit_number{
    font-size: 27px;
    display: flex;
    /* font-size: 3.5vw; */
}

.circle_inside_text{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}


.days_circle{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    /* background: linear-gradient(183.76deg, #EF2D2D -9.28%, #BA1540 107.29%); */
    border: 3px solid #BA1540 ;
    /* box-sizing: border-box;  */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.daycircle_inside{
    width: 45px;
    height: 45px;
    background-color:  black;
    border-radius: 100%;
    border: 2px solid #BA1540 ;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: 600 12px/15px "openSans";
}
.amount_text{
    font: 600 12px / 15px "openSans";
    color: white;
    text-align: center;
    margin: 10px 0 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute;
    left: 10px; */
}
.amount_text svg{
    width: 13px;
}


.pentagon_parent{
    margin: 20px;
    display:flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    border-radius: inherit;
    border-radius: 1em/.5em;
}

.pentagon{
    position: relative;
	/* width: 120px; */
    width: 100%;
	height: 120px;
	background: #002481;
    box-sizing: border-box;
	clip-path: polygon( 50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
    border-radius: inherit;
    background-repeat: round;
}

.lock{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

  .prize_background{
      width: 100%;
      /* min-height: 800px; */
      background-repeat: round;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin: 30px 0 20px 0;
      background-image: url("../images/contest_bgImage.png");
  }

  .pentagon img{
      width: 100%;
      height: 100%;
      object-fit: fill;
  }

  .containers{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    /* grid-template-columns: repeat(2, 1fr); */
    margin: 10px 10px 0 10px;
    /* column-gap: 15px;
    row-gap: 35px; */
  }
  .poligon_box{
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 150px;
    margin:0 auto;
    width: 50%;
  }
  .third-item{
     grid-column: 2/4;
     /* width: fit-content; */
     width: 120px;
  }

  .pentagon_text{
    font: 600 15px / 25px "openSans";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFDE7B;
  }

  .complete_tick{
    position: absolute;
    transform: translate(40%, 35%);
  }
  /* .prize_background img{
    width: 100%;
} */

.hexagon{
    position: relative;
    width: 10em;
    height: 17em;
    background: aqua;
    margin: auto;
    border-radius: 1em / .5em;
}

.hexagon::before , .hexagon::after {
   content: '';
   position: absolute;
   width: inherit;
   height: inherit;
   background: inherit;
   border-radius: inherit;
}

.hexagon::before{
    transform: rotate(60deg);
}
.hexagon::after{
    transform: rotate(-60deg);
}


.heading{
    color: #FFDE7B !important;
}

.loading_text{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}



.poligon {
    display: inline-block;
    position: relative;
    width: 130px;
	height: 130px;
    border: 5px solid #002481;
    background: #002481;
    box-sizing: border-box;
    border-radius: 10%;
    /* clip-path: polygon( 50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%); */
    -webkit-clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%); 
clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%);

}
  .poligon img {
    position: absolute;
    border-radius: 10%;

width: 100%;height: 100%;
    /* clip-path: polygon( 50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%); */
-webkit-clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%); 
 clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%); 
  }
  .inner_winner_box {
	margin-bottom: -4%;
	position: relative;
}
.right_star {
	position: absolute;
	width: 55.3px;
	height: 55.3px;
	margin-left: -18px;
	margin-top: -11px;
}

.left_star {
	position: absolute;
	width: 55px;
	height: 55px;
	margin-left: -38px;
	top: -19%;
	left: 0;
	transform: scaleX(-1);
}
.winner_text {
	position: relative;
	font: 400 45px/46px "PassionOne";
	letter-spacing: 0em;
	text-align: center;
	color: #ffd21f;
	z-index: 1;
}
.outer_winner_box {
	display: flex;
	width: 100%;
	justify-content: center;
	height: 125px;
    box-sizing: border-box;
	align-items: center;
}
.lgoutBig{
    display: block;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: left;
}
.logoutButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 8px 20px;
    border: 1px solid white;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 0 0 0 auto;
}
.logoutButton p{
    margin: 0 5px 0 0;
    color: white;
    font: 400 14px/15px "openSans";

}
.flexBody{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    justify-content: space-between;
}
.flexBody .okay{
    width: 40%;
    padding: 8px 12px;
}
.rules_text{
    padding: 40px 20px 40px 15px;
    box-sizing: border-box;
    width: 100%;
}
.rules_text h4{
    font: 900 17px/19px "PoppinsBold";
color: white;
text-align: left;
    margin:  0 0 10px 0;

    text-decoration: underline;
}
.rules_text p{
    font: 600 14px/19px "openSans";
    color: white;
    text-align: left;
        margin: 0 0 5px;
}
.stickBottom{
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
    padding: 0;
    background-color: white;
}
.stickBottom p{
    width: 100%;
    cursor: pointer;
    color: blue;
    margin: 0;
    padding: 4px 10px;
    text-decoration: underline;
    font: 500 17px/19px "PoppinsSemiBold";
}